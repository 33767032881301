<template>
  <div class="photo-section">
    <video ref="video" style="width: 100%;" @canplay="initCanvas">
      Stream unavailable
    </video>

    <canvas ref="canvas" style="display: none" />
  </div>
</template>

<script>
export default {
  mounted() {
    this.canvas = this.$refs.canvas;
    this.video = this.$refs.video;
    this.startCapture();
  },
  methods: {
    startCapture() {
      // console.log("OOOOOOFFFSSSOOOOLLLOOOO");
      navigator.mediaDevices
        .getUserMedia({ video: true, audio: false })
        .then((stream) => {
          this.video.srcObject = stream;
          this.video.play();
          this.stream = stream;
        })
        .catch((error) => {});
    },

    initCanvas() {
      this.canvas.setAttribute("width", this.video.videoWidth);
      this.canvas.setAttribute("height", this.video.videoHeight);
    },
  },
};
</script>

<style lang="scss" scoped>
// .photo-section {
//   position: relative;
//   img {
//     height: 500px;
//     width: 700px;
//     background: url("~@/assets/media/frames/1.png");
//     padding: 30px 10px 50px 10px;
//   }

//   .frame {
//     position: absolute;
//     top: 0;
//     left: 0;
//     height: 500px;
//     width: 700px;
//     background-repeat: no-repeat;
//     background-size: contain;
//     background-position: center;
//     // background: url("~@/assets/media/frame.png");
//   }
// }

.photo-section {
  position: relative;
}

.main-image {
  width: 281.25px;
  height: 500px;
  position: absolute;
  z-index: -1;
}

.frame {
  width: 281.25px;
  height: 500px;
}
</style>
